<!-- 外层 -->
<template>
  <div class="news_holder">
    <div class="news_title">{{ newsTitle }}</div>
    <div class="news_info">
      <span class="news_author" v-if="newsAuthor">{{ newsAuthor }}</span>
      <span class="news_date">{{ newsDate }}</span>
    </div>
    <div class="news_tag_row">
      <ul class="news_tag">
        <li :class="{ on: newsAttitudeIndex == 0 }">强烈利空</li>
        <li :class="{ on: newsAttitudeIndex == 1 }">利空</li>
        <li :class="{ on: newsAttitudeIndex == 2 }">中性</li>
        <li :class="{ on: newsAttitudeIndex == 3 }">利好</li>
        <li :class="{ on: newsAttitudeIndex == 4 }">强烈利好</li>
      </ul>
    </div>
    <div class="news_summary" v-if="newsSummary">{{ newsSummary }}</div>
    <div class="news_content" v-html="newsContent"></div>
    <div class="relief_note">
      以上内容与股票灯塔立场无关，相关内容不对各位读者构成任何投资建议，据此操作，风险自担。股市有风险，投资需谨慎。
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { sleep, setRem } from "@/utils/utils.js";
export default {
  data() {
    return {
      id: "",
      newsTitle: "",
      newsAuthor: "",
      newsDate: "",
      newsAttitudeIndex: "", //态度索引
      newsSummary: "", //摘要
      newsContent: "", //内容
    };
  },
  components: {},
  methods: {
    async getNews() {
      const { data } = await axios.get(`https://news.wedengta.com/getNews`, {
        params: {
          action: this.type == 4 ? "DiscNewsContent" : "Content",
          type: this.type,
          id: this.id,
        },
      });
      const parsedData = JSON.parse(data.content);
      console.log("parsedData", parsedData);
      this.newsTitle = parsedData.sTitle;
      this.newsSummary = parsedData.sDescription;
      this.newsAuthor = parsedData.sFrom;
      this.newsDate = this.$moment(parsedData.iTime * 1000).format("YYYY-MM-DD hh:mm");
      this.newsAttitudeIndex = this.getNewsAttitude(parsedData.vtTagInfo);
      this.newsContent = parsedData.sContent;
    },
    getNewsAttitude(vtTagInfo) {
      if (vtTagInfo.length == 0 || vtTagInfo[0].eAttiType > 6 || vtTagInfo[0].eAttiType < 0) {
        return -1;
      }
      return 5 - vtTagInfo[0].eAttiType / 1;
    },
  },
  mounted() {
    setRem(720);
    this.id = this.$route.query.id;
    this.type = this.$route.query.type || 4;
    if (this.id == undefined) {
      console.error("未指定id");
      return;
    }
    this.getNews();
  },
};
</script>

<style lang="less" scoped>
.news_holder {
  background: #fff;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 35px;
  font-size: 26px;
  .news_title {
    font-size: 34px;
    margin-bottom: 17px;
    font-weight: bold;
  }
  .news_info {
    margin-bottom: 20px;
    font-size: 24px;
    color: #555;
    .news_author {
      margin-right: 15px;
    }
    // .news_date {
    //   margin-left: 15px;
    // }
  }
  .news_summary {
    background: #f0f0f0;
    padding: 16px;
    margin-bottom: 20px;
    text-indent: 80px;
    position: relative;
    font-size: 25px;
    line-height: 1.6;
    &:before {
      content: "摘要";
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 0 0 9px 0;
      background: #f79464;
      color: #fff;
      font-size: 22px;
      padding: 0 17px;
      line-height: 1.8;
      text-indent: 0;
    }
  }
  .news_content {
    /deep/ p {
      margin-bottom: 30px;
      line-height: 1.6;
      b {
        font-weight: bold;
      }
    }
  }
  .relief_note {
    line-height: 1.6;
    font-size: 21px;
    color: #555;
  }
}

/*通用态度*/
.news_tag_row {
  margin-bottom: 30px;
}
.news_tag {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.news_tag li {
  min-width: 64px;
  height: 19px;
  line-height: 19px;
  padding: 1px 14px 0;
  font-size: 18px;
  color: #fff;
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  font-size: 0;
  &.on {
    font-size: 20px;
    height: 36px;
    line-height: 36px;
    border-radius: 7px;
  }
}
.news_tag li:nth-child(1) {
  background-color: #2a8e29;
}
.news_tag li:nth-child(2) {
  background-color: #4cba2a;
}
.news_tag li:nth-child(3) {
  background-color: #c5d106;
}
.news_tag li:nth-child(4) {
  background-color: #ff9800;
}
.news_tag li:nth-child(5) {
  background-color: #ea4410;
}
.news_tag li:nth-child(1) {
  border-radius: 7px 0 0 7px;
  &.on {
    border-radius: 7px;
  }
}
.news_tag li:nth-child(5) {
  border-radius: 0 7px 7px 0;
  &.on {
    border-radius: 7px;
  }
}
</style>
